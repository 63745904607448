.conversejs {
    converse-chat-toolbar {
        justify-content: space-between !important;

        converse-message-limit-indicator {
            padding: 0.75em 0.5em;
        }

        .send-button {
            color: var(--background-color);
            border-radius: 0;
        }

        .btn-toolbar {
            background-color: var(--background-color);
            box-sizing: border-box;
            width: 100%;
            justify-content: space-between;
            margin-bottom: 1px; // So that bottom border on toolbar buttons is visible

            .btn {
                padding: 0.25em 0.5em !important;
            }

            .fa, .fa:hover,
            .far, .far:hover,
            .fas, .fas:hover {
                color: var(--background-color);
                font-size: var(--font-size-large);
                svg {
                    fill: var(--background-color);
                }
            }
            .unencrypted a,
            .unencrypted {
                color: var(--text-color);
            }
        }
    }

    .chatbox {
        .send-button {
            background-color: var(--chat-color);
        }
    }

    .chatroom {
        .send-button {
            background-color: var(--muc-color);
        }
    }


    .chatbox {
        converse-chat-toolbar {
            color: var(--chat-color);
            background-color: var(--background-color);
            .message-limit,
            .fas, .fas:hover,
            .far, .far:hover,
            .fa, .fa:hover {
                color: var(--chat-color);
                svg, svg:hover {
                    fill: var(--chat-color);
                }
            }
            button {
                &:focus {
                    outline-color: var(--chat-color) !important;
                }
                &:disabled .fa {
                    color: var(--disabled-color);
                    &:hover {
                        color: var(--disabled-color);
                    }
                    svg, svg:hover {
                        fill: var(--disabled-color);
                    }
                }
            }
        }
    }

    .chatroom {
        converse-chat-toolbar {
            color: var(--muc-color);
            .fas, .fas:hover,
            .far, .far:hover,
            .fa, .fa:hover {
                color: var(--muc-color);
                font-size: var(--font-size-large);
                svg {
                    fill: var(--muc-color);
                }
            }
            button {
                &:focus {
                    outline-color: var(--muc-color) !important;
                }
                &:disabled .fa {
                    color: var(--disabled-color);
                    &:hover {
                        color: var(--disabled-color);
                    }
                    svg, svg:hover {
                        fill: var(--disabled-color);
                    }
                }
            }
        }
    }
}
