.conversejs {
    converse-user-details-modal {
        .nav-pills {
            margin-bottom: 1em;
        }

        .badge-roster-group {
            background-color: var(--chat-color);
        }

        #profile-tabpanel {
            label {
                color: var(--secondary-color);
                margin-inline-end: 0.5em;
            }
        }

        .remove-contact {
            margin-inline-end: 0.5em;
        }
    }
}
