converse-root {
    &.converse-fullscreen,
    &.converse-overlayed {
        bottom: 0;
        left: 0;
        padding-inline-start: env(safe-area-inset-left);
        padding-inline-end: env(safe-area-inset-right);
        position: fixed;
        z-index: 1031; // One more than bootstrap navbar
    }

    &.converse-fullscreen {
        height: 100vh;
        width: 100vw;
    }

    &.converse-embedded {
        position: relative;
    }
}
