.conversejs {
    #controlbox {
        #chatrooms {
            padding: 0;

            .add-chatroom {
                input[type=button],
                input[type=submit],
                input[type=text] {
                    width: 100%;
                }
                margin: 0;
                padding: 0;
            }
        }

        .open-rooms-toggle, .open-rooms-toggle converse-icon {
            color: var(--muc-color) !important;
            &:hover {
                color: var(--muc-color-hover) !important;
            }
        }

        .open-rooms-toggle {
            white-space: nowrap;
            converse-icon {
                margin-inline-start: 0.25em;
                padding-bottom: 0.1em;
            }
        }
    }
}
