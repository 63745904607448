@import "shared/styles/_mixins.scss";

converse-registration-form {
    padding-top: 0;
    padding-bottom: 0;

    .alert {
        margin: auto;
        max-width: 50vw;
    }

    #converse-register {
        @include fade-in;

        padding-top: 0;
        background-color: var(--background-color);

        .title {
            font-weight: bold;
        }

        .input-group {
            input {
                height: auto;
            }
            .input-group-text {
                color: var(--text-color);
                background-color: var(--background-color);
            }
        }

        .form-errors {
            color: var(--error-color);
            margin: 1em 0;
        }

        .form-help .url {
            font-weight: bold;
            color: var(--link-color);
        }

        .instructions {
            color: gray;
            font-size: 85%;
            &:hover {
                color: var(--foreground-color);
            }
        }
    }
}
