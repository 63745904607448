&[data-converse-theme="classic"],
&[data-bs-theme="classic"] {
    $theme: 'classic' !default;

    // Hex color values
    // ----------------
    --blue: #387592;
    --dark-blue: #397491;
    --dark-green: #1e9652;
    --dark-red: #d24e2b;
    --gray: #818479;
    --green: #3aa569;
    --light-blue: #578ea9;
    --lighter-blue: #eff4f7;
    --orange: #e7a151;
    --redder-orange: #e77051;

    // Base variables
    // --------------
    --foreground-color: #666;
    --background-color: #efefef;

    // Bootstrap variables
    --primary-color: var(--blue) !important;
    --secondary-color: var(--light-blue) !important;
    --success-color: var(--green);
    --danger-color: var(--dark-red);
    --warning-color: var(--orange);
    --info-color: var(--light-blue);
    --converse-highlight-color: var(--dark-red);

    // Online status indicators
    --chat-status-away: var(--orange);
    --chat-status-busy: var(--red);
    --chat-status-offline: gray;
    --chat-status-online: var(--green);

    --controlbox-color: var(--light-blue);
    --disabled-color: gray;
    --error-color: var(--dark-red);
    --focus-color: var(--light-blue);
    --heading-color: var(--background-color);
    --headlines-color: var(--orange);
    --link-color: #2D3991;
    --subdued-color: gray;

    // The background when selecting text with your mouse
    --selection-color: black;

    // Used to indicate selected or open items in lists.
    --highlight-color: var(--lighter-blue);
    --highlight-color-hover: var(--lighter-blue);

    // 1:1 Chat-related colors
    --chat-color: var(--dark-green);
    --chat-header-fg-color: var(--background-color);
    --chat-header-bg-color: var(--chat-color);

    // MUC-related colors
    --muc-color: var(--redder-orange);
    --muc-header-fg-color: var(--background-color);
    --muc-header-bg-color: var(--muc-color);

    // Derived colors
    // --------------
    --brand-heading-color: var(--blue);
    --chat-color-hover: var(--chat-color);
    --danger-color-hover: var(--danger-color);
    --global-background-color: var(--dark-blue);
    --link-color-hover: var(--link-color);
    --muc-color-hover: var(--muc-color);
    --primary-color-hover: var(--dark-blue);
    --secondary-color-hover: #ddd;
    --success-color-hover: var(--success);
    --warning-color-hover: #d2842b;
}
