@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.conversejs {
    #controlbox {
        #converse-login-panel {
            padding-top: 0;
            padding-bottom: 0;
            flex-direction: row;
        }

        .toggle-register-login {
            font-weight: bold;
        }

        .controlbox-pane {
            .switch-form {
                text-align: center;
                padding: 0.5em 0;
            }
        }
    }
}

.conversejs {
    converse-chats {
        &.converse-overlayed {
            #controlbox {
                converse-login-form {
                    fieldset {
                        margin-bottom: 0.5em;
                    }
                }

                .login-trusted {
                    white-space: nowrap;
                    font-size: 90%;
                    margin: 1em 0;
                }

                #converse-login-panel {
                    height: 100%;
                }

                #converse-login-trusted {
                    margin-top: 0.5em;
                }

                #converse-register, #converse-login {
                    @include make-col(12);
                    padding-bottom: 0;
                }

                #converse-register {
                    .button-cancel {
                        font-size: 90%;
                    }
                }
            }
        }

        &.converse-embedded,
        &.converse-fullscreen {
            #controlbox {
                .toggle-register-login {
                    line-height: var(--line-height-huge);
                }

                #converse-register, #converse-login {
                    margin: auto;
                    @include make-col-ready();
                    @include make-col(8);

                    @include media-breakpoint-up(sm) {
                        @include make-col(8);
                    }
                    @include media-breakpoint-up(md) {
                        @include make-col(8);
                    }
                    @include media-breakpoint-up(lg) {
                        @include make-col(6);
                    }
                    .title, .instructions {
                        margin: 0.5em 0;
                    }
                    input[type=submit],
                    input[type=button] {
                        width: auto;
                    }
                }
            }
        }
    }
}
