.conversejs {
    .set-xmpp-status {
        .chat-status--online {
            color: var(--chat-status-online);
        }
        .chat-status--busy {
            color: var(--chat-status-busy);
        }
        .chat-status--away {
            color: var(--chat-status-away);
        }
        .far.fa-circle,
        .fa-times-circle {
            color: var(--secondary-color);
        }
        .chat-status {
            padding-inline-end: 0.5em;
        }
    }
}

