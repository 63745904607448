.conversejs {
    converse-chats {
        &.converse-overlayed {
            > .row {
                flex-direction: row-reverse;
            }
            &.converse-chatboxes {
                z-index: 1031; // One more than bootstrap navbar
                position: fixed;
                bottom: 0;
                right: 0;
                left: 1em;
            }
        }

        &.converse-fullscreen {
            flex-wrap: nowrap;
        }

        &.converse-fullscreen {
            &.converse-chatboxes {
                position: fixed;
                bottom: 0;
                right: 0;
                left: 0;
            }
        }

        &.converse-embedded {
            bottom: auto;
            height: 100%; // When embedded, it fills the containing element
            position: relative;
            right: auto;
            width: 100%;

            &.converse-chatboxes {
                z-index: 1031; // One more than bootstrap navbar
                position: inherit;
                flex-wrap: nowrap;
                bottom: auto;
                height: 100%;
                width: 100%;
            }
        }
    }
}
