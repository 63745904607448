.conversejs {
    converse-emoji-dropdown,
    converse-dropdown {

        &.dropup {
            &.dropup--left {
                .dropdown-menu {
                    right: 100%;
                    left: auto;
                }
            }
        }

        .btn--standalone {
            padding: 0 0.2em;
            margin: 0;
        }

        .dropdown-toggle--no-caret {
            &:before,
            &:after {
                display: none !important;
            }
        }

        .dropdown-menu {
            background: var(--background-color);
            margin-top: -0.2em !important;
            box-shadow: var(--raised-el-shadow);
        }

        .dropdown-item {
            text-align: start;
            color: var(--text-color) !important;
            font-size: var(--font-size);
            padding: 0.5rem 1rem;
            converse-icon {
                margin-top: -0.1em;
                width: 1.25em;
                margin-inline-end: 0.25rem;
                margin-inline-start: 0.25rem;
            }
            &:active, &.selected {
                background-color: var(--highlight-color);
            }
            &:hover {
                background-color: var(--highlight-color-hover);
            }
        }
    }
}
