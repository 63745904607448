&[data-converse-theme="nordic"],
&[data-bs-theme="nordic"] {
    $theme: 'nordic' !default;

    // Darker gray theme colors
    --polar-night-1: #2e3440;
    --polar-night-2: #3b4252;
    --polar-night-3: #434c5e;
    --polar-night-4: #4c566a;

    --snow-storm-1:  #d8dee9;
    --snow-storm-2:  #e5e9f0;
    --snow-storm-3:  #eceff4;

    --green-1:       #5D9795;
    --green-2:       #387C79;
    --green-3:       #8FBCBA;
    --green-4:       #0B4C4A;

    --blue-1:        #5D9795;
    --blue-2:        #475D8B;

    --orange:           #D0925F;
    --orange-darker:    #A76632;
    --purple:           #b48ead;
    --red:              #bf616a;
    --yellow:           #ebcb8b;

    // Base variables
    // --------------
    --background-color: var(--snow-storm-2);
    --foreground-color: var(--polar-night-2);

    // Bootstrap variables
    --primary-color: var(--green-2) !important;
    --primary-color-hover: var(--green-3) !important;
    --secondary-color: var(--blue-1) !important;
    --success-color: var(--green-3);
    --danger-color: var(--red);
    --warning-color: var(--orange);
    --info-color: var(--purple);
    --converse-highlight-color: var(--red);

    // Online status indicators
    --chat-status-away: var(--orange);
    --chat-status-busy: var(--red);
    --chat-status-offline: gray;
    --chat-status-online: var(--green-4);

    --controlbox-color: var(--purple);
    --disabled-color: var(--secondary-color);
    --error-color: var(--red);
    --focus-color: var(--secondary-color);
    --heading-color: var(--purple);
    --headlines-color: var(--yellow);
    --link-color: var(--blue-2);
    --subdued-color: gray;

    // The background when selecting text with your mouse
    --selection-color: var(--green-1);

    // Used to indicate selected or open items in lists.
    --highlight-color: var(--snow-storm-2);
    --highlight-color-hover: var(--snow-storm-1);

    // 1:1 Chat-related colors
    --chat-color: var(--green-1);
    --chat-header-fg-color: var(--background-color);
    --chat-header-bg-color: var(--chat-color);

    // MUC-related colors
    --muc-color: var(--orange);
    --muc-header-fg-color: var(--background-color);
    --muc-header-bg-color: var(--muc-color);

    // Derived colors
    // --------------
    --brand-heading-color: var(--green-4);
    --chat-color-hover: var(--chat-color);
    --danger-color-hover: var(--danger-color);
    --global-background-color: var(--background-color);
    --link-color-hover: var(--link-color);
    --muc-color-hover: var(--muc-color);
    --primary-color-hover: var(--purple);
    --secondary-color-hover: var(--snow-storm-1);
    --success-color-hover: var(--success);
    --warning-color-hover: var(--orange);
}
