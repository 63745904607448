converse-omemo-fingerprints {
    .fingerprints {
        .list-group-item {
            color: var(--foreground-color);
        }
    }
    .fingerprint {
        margin-top: 1em;
        display: block;
    }
}
