converse-icon {
    display: inline-block;
    padding: 0;
    margin: 0;
    svg {
	fill: var(--secondary-color);
    }
    &.clickable {
	&:hover {
	    svg {
		fill: var(--link-color);
	    }
	}
    }
}

a, .clickable {
    converse-icon {
	&:hover {
	    svg {
		fill: var(--link-color);
	    }
	}
    }
}
