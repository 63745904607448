.conversejs {

    #chatrooms {
        .muc-domain-group-toggle {
            margin: 0.75em 0 0.25em 0;
        }

        .muc-domain-group-toggle, .muc-domain-group-toggle .fa {
            color: var(--muc-color);
            &:hover {
                color: var(--muc-color-hover);
            }
        }

        converse-rooms-list {
            display: block;
            margin-bottom: 1em;

            svg {
                fill: var(--muc-color);
            }

            .list-item {
                .open-room {
                    display: flex;
                    flex-direction: row;
                    span {
                        padding-top: 0.5em;
                        padding-inline-end: 0.5em;
                    }
                }
            }
        }
    }
}
