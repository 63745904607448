converse-message-actions {
    margin-inline-start: 0.5em;

    .chat-msg__actions {
        .dropdown-menu {
            min-width: 5rem;
        }
        i {
            color: var(--foreground-color);
            font-size: 70%;
        }
        button {
            border: none;
            background: transparent;
            color: var(--foreground-color);
            padding: 0 0.25em;
        }
        .btn--standalone {
            opacity: 0;
            margin-top: -0.2em;
            &:focus {
                opacity: 1;
            }
        }
        .chat-msg__action {
            width: 100%;
            padding: 0.5em 1em;
            text-align: start;
            white-space: nowrap;

            converse-icon {
                margin-inline-end: 0.25em;
            }

            &:hover {
                color: var(--text-color);
                background-color: var(--secondary-color);
            }
        }
    }
}
