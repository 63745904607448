.conversejs {

    .alert-info, .alert-danger {
        h3 {
            color: var(--background-color);
            font-size: large;
        }

        .modal-title {
            font-size: 110%;
        }

        .close {
            color: var(--background-color);
        }
    }

    .alert-info {
        color: var(--background-color);
        background-color: var(--info-color);
        border-color: var(--info-dark);
    }

    .alert-danger {
        .disconnect-msg {
            color: var(--background-color) !important;
        }

        color: var(--background-color);
        border-color: var(--danger-color-dark);
        background-color: var(--danger-color);
    }

}
