&[data-converse-theme="dracula"],
&[data-bs-theme="dracula"] {
    $theme: 'dracula' !default;

    // Hex color values
    // ----------------
    --cyan: #8be9fd;
    --green: #50fa7b;
    --orange: #ffb86c;
    --pink: #ff79c6;
    --purple: #bd93f9;
    --red: #ff5555;
    --yellow: #f1fa8c;
    --gray: #6272a4;
    --darkgray: #212529;

    // Base variables
    // --------------
    --background-color: #282a36;
    --foreground-color: #f8f8f2;

    // Bootstrap variables
    --primary-color: var(--purple) !important;
    --secondary-color: var(--pink) !important;
    --success-color: var(--green);
    --danger-color: var(--red);
    --warning-color: var(--orange);
    --info-color: var(--yellow);

    --converse-body-bg: var(--background-color);
    --converse-highlight-color: var(--yellow) !important;
    .list-group-item {
        --converse-list-group-color: var(--foreground-color);
        &.active {
            --converse-list-group-active-color: var(--background-color) !important;
        }
    }

    // Online status indicators
    --chat-status-away: var(--orange);
    --chat-status-busy: var(--red);
    --chat-status-offline: var(--gray);
    --chat-status-online: var(--green);

    --controlbox-color: var(--purple);
    --disabled-color: var(--gray);
    --error-color: var(--red);
    --focus-color: var(--pink);
    --heading-color: var(--purple);
    --headlines-color: var(--pink);
    --link-color: var(--cyan);
    --subdued-color: var(--gray);

    // The background when selecting text with your mouse
    --selection-color: black;

    // Used to indicate selected or open items in lists.
    --highlight-color: var(--gray);
    --highlight-color-hover: var(--darkgray);

    // 1:1 Chat-related colors
    --chat-color: var(--green);
    --chat-header-fg-color: var(--chat-color);
    --chat-header-bg-color: var(--background-color);

    // MUC-related colors
    --muc-color: var(--orange);
    --muc-header-fg-color: var(--muc-color);
    --muc-header-bg-color: var(--background-color);

    // Derived colors
    // --------------
    --brand-heading-color: var(--cyan);
    --chat-color-hover: var(--chat-color);
    --danger-color-hover: var(--danger-color);
    --global-background-color: var(--background-color);
    --link-color-hover: var(--link-color);
    --muc-color-hover: var(--muc-color);
    --primary-color-hover: var(--purple);
    --secondary-color-hover: var(--secondary-color);
    --success-color-hover: var(--success);
    --warning-color-hover: var(--orange);
}
